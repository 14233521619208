<template>
  <v-app-bar
    v-if="$store.state.valorEnviado === false"
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />
    <div class="mx-3" />
  </v-app-bar>
</template>

<script>

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      rutas: [
        { texto: 'Cambiar contraseña', accion: 1 },
        { texto: 'Cerrar sesión', accion: 2 },
      ],
    }),

    computed: {
      ...mapState(['drawer']),
      ...mapState(['valorEnviado']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        console.log(logOut); // eslint-disable-line
        if (logOut === true) {
          localStorage.setItem('sedeCorteData', null)
          localStorage.setItem('usuarioDataCSJLA', null)
          this.$router.push('/login')
        }
      },
    },
  }
</script>
